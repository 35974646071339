import Proj1 from "../assets/Proj1.png";
//import Proj2 from "../assets/proj2.jpg";
//import Proj3 from "../assets/proj3.webp";
//import Proj4 from "../assets/proj4.webp";
//import Proj5 from "../assets/proj5.jpg";
//import Proj6 from "../assets/proj6.png";

export const ProjectList = [
  {
    name: "Project 1",
    image: Proj1,
    skills: " n/a ",
  },
  {
    name: "Project 2",
    image: Proj1,
    skills: " n/a ",
  },
  {
    name: " Project 3 ",
    image: Proj1,
    skills: " Project 3 ",
  },
  {
    name: " Project 4 ",
    image: Proj1,
    skills: " n/a ",
  },
  

];