import React from "react";

import '../styles/Footer.css';

function Footer() {
  return (
     <div className='footer'>
          
          <p> &copy; 2023 stacisoutherland.com</p>
     </div>
 );
  
}

export default Footer;
